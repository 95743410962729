import { ConfigurationItemOption } from 'components/ConfigurationItem/ConfigurationItem'
import { OptionItemContent } from 'components/OptionItem/OptionItem'
import * as DateFns from 'date-fns'
import { isDate, parse } from 'date-fns'
import {
    AccountType,
    ConfigurationDisplayType,
    ConfigurationHouseConnection,
    ConfigurationJunctionBox,
    ConfigurationOpticFiber,
    ConfigurationType,
    Customize,
    HouseConnectionOption,
    JunctionBoxOption,
    OpticFiberOption,
    OptionConfiguration,
    PortabilityContractHolder,
    PortabilityTelephone,
    PostCodeDetail,
} from 'graphql/types'
import IBAN from 'iban'
import { cloneDeep } from 'lodash'
import { InstallationDetailsFields } from 'pages/InstallationDetails/useInstallationDetailsReducer'
import { Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import BankDetailsActions, { BankDetailsAction } from 'store/BankDetails/BankDetails.actions'
import { BankDetailsState } from 'store/BankDetails/BankDetails.reducer'
import ContactDataActions, { ContactDataAction } from 'store/ContactData/ContactData.actions'
import { ContactDataState } from 'store/ContactData/ContactData.reducer'
import GeneralStateActions, { ConfigPayload, GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import {
    Error,
    GeneralState,
    InstallationDetailsState,
    LoadState,
    OnEditClickTypes,
    ViewType,
} from 'store/GeneralState/GeneralState.reducer'
import PortabilityStateActions, { PortabilityStateAction } from 'store/PortabilityState/PortabilityState.actions'
import { PortabilityAddress, PortabilityViewFields } from 'store/PortabilityState/PortabilityState.reducer'
import { AppState } from 'store/store'
import { isPhoneNumber } from 'utils/testable/checkPhoneNumber'
import { birthDateIsValid, mailIsValid, salutationIsCompany } from 'utils/testable/functions'
import { getBankAccountApproval } from 'utils/testable/getBankAccountApproval'
import useURLParams from 'utils/URLParamsContex'

interface EditReducerReturn {
    editInLineErrors: string[]
    loadState: LoadState
    onBack: () => void
    onSubmit: () => void
    errors: Error[]
    editType: OnEditClickTypes | undefined
    disabledSubmit: boolean
    disabledBack: boolean

    // Desired Date
    editEarliestDatePossible: boolean
    setEditEarliestDatePossible: (value: boolean) => void
    editDesiredDate: Date | null
    setEditDesiredDate: (value: Date | null) => void
    minDate: () => Date
    maxDate: () => Date | number
    formatDateString: (date: Date) => string
    shouldDisableDate: (day: Date | null) => boolean

    // Bank Details
    editBankDetails: BankDetailsState
    customizeJsData: Customize | undefined
    showTwoConsentsIBAN: boolean | undefined
    consentLabel: string
    consentLabel2: string
    invoiceOptions: ConfigurationItemOption[]
    dayOfTransferOptions: ConfigurationItemOption[]
    generalState: GeneralState
    setEditAccountHolderAdditionalAddrInfo: (payload: string | undefined) => void
    setEditAccountHolderCountryAddr: (payload: string | undefined) => void
    setEditAccountHolderCity: (payload: string) => void
    setEditAccountHolderHouseNumber: (payload: string) => void
    setEditAccountHolderLastName: (payload: string) => void
    setEditAccountHolderName: (payload: string) => void
    setEditAccountHolderSalutation: (payload: string) => void
    setEditAccountHolderStreet: (payload: string) => void
    setEditAccountHolderTitle: (payload: string) => void
    setEditAccountHolderZip: (payload: string) => void
    setEditAccountHolderCompany?: (payload: string) => void
    setEditAccountHolderCompanyLegalForm?: (payload: string) => void
    setEditIban: (payload: string) => void
    setEditDifferentAccountHolder: (payload: boolean) => void
    setEditAccountType: (payload: AccountType) => void
    setEditInvoiceSend: (payload: string) => void
    setEditDayOfTransfer: (payload: string) => void
    editSelectedInvoiceSendOption: string
    setEditConsent: (payload: boolean) => void
    setEditConsent2: (payload: boolean) => void
    setEditBankDetailsPartial: (payload: Partial<BankDetailsState>) => void
    setEditBankDetails: (payload: BankDetailsState) => void
    editDayOfTransfer: string
    setEditBankAddress: (zip: string, city: string, street: string, houseNumber: string, additionalInfo: string) => void

    // Contact Data
    availability: AvailabilityCheckState
    b2b: boolean
    editContactData: ContactDataState
    setEditContactDataPartial: (payload: Partial<ContactDataState>) => void
    setEditContactData: (payload: ContactDataState) => void
    setEditContactAddress: (
        zip: string,
        city: string,
        street: string,
        houseNumber: string,
        additionalInfo: string,
        delivery: boolean,
    ) => void

    // Installation Details
    editInstallationDetails: InstallationDetailsState
    setEditInstallationDetails: (payload: InstallationDetailsState) => void
    convertJunctionBoxOptionToOptionItemContent: (junctionBox: ConfigurationJunctionBox) => OptionItemContent[]
    convertHouseConnectionOptionToOptionItemContent: (
        houseConnection: ConfigurationHouseConnection,
    ) => OptionItemContent[]
    convertOpticFiberOptionToOptionItemContent: (opticFiber: ConfigurationOpticFiber) => OptionItemContent[]
    convertYoutubeLinkToID: (link: string) => string
    onChange: (field: InstallationDetailsFields, value: string | number) => void
    submitButtonText: string | undefined

    // Telephone Configuration
    editConfigurationMapState: Map<string, string | string[]>
    convertOptionToOptionItem: (
        option: OptionConfiguration,
        displayType: ConfigurationDisplayType,
        optionsLength: number,
    ) => ConfigurationItemOption
    setConfigurationMapCheckState: (payload: ConfigPayload) => void
    setConfigurationMapRadioState: (payload: ConfigPayload) => void
    setEditConfigurationMapState: Dispatch<SetStateAction<Map<string, string | string[]>>>
    submitButtonTextAlt: boolean

    // Portability View
    editAddOption: (field: PortabilityViewFields) => void
    editAddress: PortabilityAddress
    editContractHolderOptions: PortabilityContractHolder[]
    editEndOfContract: string
    isOptionEmpty: (option: PortabilityTelephone | PortabilityContractHolder) => boolean
    missingFields: string[]
    onChangePortability: (field: PortabilityViewFields, label: string, index: number, value: string) => void
    editPhoneOptions: PortabilityTelephone[]
    editRemoveOption: (field: PortabilityViewFields) => void
    editProviderOptions: string[]
    editSelectedProvider: string
    editSelectedRadios: string[]
    phoneNumbersError: (field: string, index: number) => boolean
    handleEditAddressSelected: (data: PostCodeDetail) => void
    handleEditSelectProvider: (_: React.ChangeEvent<any>, value: string) => void
    handleEditInputProvider: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBackPortability: () => void
    displayDeliveryAddress: boolean
}

export const useEditReducer: () => EditReducerReturn = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch =
        useDispatch<Dispatch<GeneralStateActions | BankDetailsActions | ContactDataActions | PortabilityStateActions>>()
    const { B2B } = useURLParams()
    const {
        inLineErrors,
        loadState,
        errors,
        pagesList,
        editType,
        customizeJsData,
        desiredDate,
        earliestDatePossible,
        startOfDelivery,
        configurationMapState,
        bankDetails,
        generalState,
        availability,
        contactData,
        installationDetails,
        currentView,
        providerOptions,
        address,
        contractHolderOptions,
        endOfContract,
        phoneOptions,
        selectedProvider,
        selectedRadios,
        selectedProductCategories,
        availableProductCategories,
    } = useSelector((appState: AppState) => {
        return {
            selectedProductCategories: appState.generalState.selectedProductCategories,
            availableProductCategories: appState.generalState.availableProductCategories,
            inLineErrors: appState.generalState.inLineErrors,
            generalState: appState.generalState,
            loadState: appState.generalState.loadState,
            pagesList: appState.generalState.pagesList,
            errors: appState.generalState.errors,
            editType: appState.generalState.editType,
            currentView: appState.generalState.currentView,
            customizeJsData: appState.generalState.customizeJsData,
            desiredDate: appState.generalState.desiredDate,
            earliestDatePossible: appState.generalState.earliestDatePossible,
            startOfDelivery: appState.generalState.startOfDelivery,
            configurationMapState: appState.generalState.configuration,
            bankDetails: appState.bankDetails,
            availability: appState.availabilityCheck,
            contactData: appState.contactData,
            installationDetails: appState.generalState.installationDetails,
            providerOptions: appState.portabilityState.providerOptions,
            address: appState.portabilityState.address,
            contractHolderOptions: appState.portabilityState.contractHolderOptions,
            endOfContract: appState.portabilityState.endOfContract,
            phoneOptions: appState.portabilityState.phoneOptions,
            selectedProvider: appState.portabilityState.selectedProvider,
            selectedRadios: appState.portabilityState.selectedRadios,
        }
    })

    const [editEarliestDatePossible, setEditEarliestDatePossible] = useState<boolean>(cloneDeep(earliestDatePossible))
    const [editDesiredDate, setEditDesiredDate] = useState<Date | null>(cloneDeep(desiredDate))
    const [editBankDetails, setEditBankDetails] = useState<BankDetailsState>(cloneDeep(bankDetails))
    const [, setEditBankDetailsPartial] = useState<Partial<BankDetailsState>>(editBankDetails)
    const [editSelectedInvoiceSendOption, setEditInvoiceSend] = useState<string>(
        cloneDeep(String(configurationMapState.get('invoiceSend'))),
    )
    const [editContactData, setEditContactData] = useState<ContactDataState>(cloneDeep(contactData))
    const [, setEditContactDataPartial] = useState<Partial<ContactDataState>>(editContactData)
    const [editAddress, setEditAddress] = useState<PortabilityAddress>(cloneDeep(address))
    const [editContractHolderOptions, setEditContractHolderOptions] = useState<PortabilityContractHolder[]>(
        cloneDeep(contractHolderOptions),
    )
    const [editEndOfContract, setEditEndOfContract] = useState<string>(cloneDeep(endOfContract))
    const [editPhoneOptions, setEditPhoneOptions] = useState<PortabilityTelephone[]>(cloneDeep(phoneOptions))
    const [editProviderOptions] = useState<string[]>(cloneDeep(providerOptions))
    const [editSelectedProvider, setEditSelectedProvider] = useState<string>(cloneDeep(selectedProvider))
    const [editSelectedRadios, setEditSelectedRadios] = useState<string[]>(cloneDeep(selectedRadios))
    const [editInstallationDetails, setEditInstallationDetails] = useState<InstallationDetailsState>(
        cloneDeep(installationDetails),
    )
    const [editConfigurationMapState, setEditConfigurationMapState] = useState<Map<string, string | string[]>>(
        cloneDeep(configurationMapState),
    )
    const [editInLineErrors, setEditInLineErrors] = useState<string[]>(cloneDeep(inLineErrors))
    const showTwoConsentsIBAN =
        customizeJsData &&
        customizeJsData.bankDetails.useSecondConsentIBAN === true &&
        editBankDetails.accountType === 'IBAN'

    const [areThereAnyMissingContactDataFields] = useMemo(() => {
        const missingFields = [
            'personalSalutation',
            'personalName',
            'personalLastName',
            'personalEmail',
            'personalBirthDate',
            'personalTelephone',
            'personalMobilePhone',
            'deliverySalutation',
            'deliveryName',
            'deliveryLastName',
            'deliveryStreet',
            'deliveryHouseNumber',
            'deliveryZip',
            'deliveryCity',
            'billingSalutation',
            'billingName',
            'billingLastName',
            'billingStreet',
            'billingHouseNumber',
            'billingZip',
            'billingCity',
        ]
        const newErrors: string[] = cloneDeep(editInLineErrors)
        if (editContactData.personalSalutation !== '') {
            const index = missingFields.indexOf('personalSalutation')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (editContactData.personalName !== '') {
            const index = missingFields.indexOf('personalName')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (editContactData.personalLastName !== '') {
            const index = missingFields.indexOf('personalLastName')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (editContactData.personalEmail !== '') {
            const index = missingFields.indexOf('personalEmail')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (editContactData.personalBirthDate !== '') {
            const index = missingFields.indexOf('personalBirthDate')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (editContactData.personalTelephone !== '') {
            if (isPhoneNumber(editContactData.personalTelephone)) {
                const index = missingFields.indexOf('personalTelephone')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
        } else {
            const index = missingFields.indexOf('personalTelephone')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (editContactData.personalMobilePhone !== '') {
            if (isPhoneNumber(editContactData.personalMobilePhone)) {
                const index = missingFields.indexOf('personalMobilePhone')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
        } else {
            const index = missingFields.indexOf('personalMobilePhone')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (editContactData.deviatingDeliveryAddress === false) {
            const index = missingFields.indexOf('deliverySalutation')
            if (index !== -1) {
                missingFields.splice(index, 7)
            }
        } else {
            if (editContactData.deliverySalutation !== '') {
                const index = missingFields.indexOf('deliverySalutation')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editContactData.deliveryName !== '') {
                const index = missingFields.indexOf('deliveryName')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editContactData.deliveryLastName !== '') {
                const index = missingFields.indexOf('deliveryLastName')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editContactData.deliveryZip !== '') {
                if (editContactData.deliveryZip.length === 5) {
                    const index = missingFields.indexOf('deliveryZip')
                    if (index !== -1) {
                        missingFields.splice(index, 1)
                    }
                }
            }
            if (editContactData.deliveryCity !== '') {
                const index = missingFields.indexOf('deliveryCity')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editContactData.deliveryStreet !== '') {
                const index = missingFields.indexOf('deliveryStreet')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editContactData.deliveryHouseNumber !== '') {
                const index = missingFields.indexOf('deliveryHouseNumber')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
        }
        if (editContactData.deviatingBillingAddress === false) {
            const index = missingFields.indexOf('billingSalutation')
            if (index !== -1) {
                missingFields.splice(index)
            }
        } else {
            if (editContactData.billingSalutation !== '') {
                const index = missingFields.indexOf('billingSalutation')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editContactData.billingName !== '') {
                const index = missingFields.indexOf('billingName')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editContactData.billingLastName !== '') {
                const index = missingFields.indexOf('billingLastName')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editContactData.billingZip !== '') {
                if (editContactData.billingZip.length === 5) {
                    const index = missingFields.indexOf('billingZip')
                    if (index !== -1) {
                        missingFields.splice(index, 1)
                    }
                }
            }
            if (editContactData.billingCity !== '') {
                const index = missingFields.indexOf('billingCity')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editContactData.billingStreet !== '') {
                const index = missingFields.indexOf('billingStreet')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editContactData.billingHouseNumber !== '') {
                const index = missingFields.indexOf('billingHouseNumber')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
        }

        // check B2B MODE ON
        if (B2B === true) {
            if (editContactData.company === '') {
                missingFields.push('company')
            }

            if (editContactData.deviatingBillingAddress && editContactData.billingCompany === '') {
                missingFields.push('billingCompany')
            }

            if (editContactData.deviatingDeliveryAddress && editContactData.deliveryCompany === '') {
                missingFields.push('deliveryCompany')
            }

            if (customizeJsData && customizeJsData.contactDataViewConfiguration) {
                if (
                    editContactData.companyLocation === '' &&
                    customizeJsData.contactDataViewConfiguration.enableCompanyLocation
                ) {
                    missingFields.push('companyLocation')
                }

                if (
                    editContactData.companyLegalForm === '' &&
                    customizeJsData.contactDataViewConfiguration.enableLegalForm
                ) {
                    missingFields.push('companyLegalForm')
                }

                if (
                    editContactData.companyId === '' &&
                    customizeJsData.contactDataViewConfiguration.enableCompanyTaxID
                ) {
                    missingFields.push('companyTaxId')
                }

                if (
                    editContactData.deviatingBillingAddress &&
                    editContactData.billingCompanyLegalForm === '' &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    missingFields.push('billingCompanyLegalForm')
                }

                if (
                    editContactData.deviatingDeliveryAddress &&
                    editContactData.deliveryCompanyLegalForm === '' &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    missingFields.push('deliveryCompanyLegalForm')
                }
            }
        } else {
            // salutation === company but b2b === false
            if (salutationIsCompany(editContactData.personalSalutation) && editContactData.company === '') {
                missingFields.push('company')
            }

            if (
                salutationIsCompany(editContactData.billingSalutation) &&
                editContactData.deviatingBillingAddress &&
                editContactData.billingCompany === ''
            ) {
                missingFields.push('billingCompany')
            }

            if (
                salutationIsCompany(editContactData.deliverySalutation) &&
                editContactData.deviatingDeliveryAddress &&
                editContactData.deliveryCompany === ''
            ) {
                missingFields.push('deliveryCompany')
            }

            if (customizeJsData && customizeJsData.contactDataViewConfiguration) {
                if (
                    salutationIsCompany(editContactData.billingSalutation) &&
                    editContactData.deviatingBillingAddress &&
                    editContactData.billingCompanyLegalForm === '' &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    missingFields.push('billingCompanyLegalForm')
                }

                if (
                    salutationIsCompany(editContactData.deliverySalutation) &&
                    editContactData.deviatingDeliveryAddress &&
                    editContactData.deliveryCompanyLegalForm === '' &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    missingFields.push('deliveryCompanyLegalForm')
                }
            }
        }
        if (customizeJsData && customizeJsData.contactDataViewConfiguration.existingCustomerForm === true) {
            if (editContactData.existingCustomerRadio === 'yes' && editContactData.existingCustomerNumber === '') {
                missingFields.push('existingCustomer')
            }
        }

        return [missingFields, newErrors]
    }, [editContactData])
    const [areThereAnyMissingBankDetailsFields] = useMemo(() => {
        let missingFields = [
            'accountHolderZip',
            'accountHolderSalutation',
            'accountHolderName',
            'accountHolderLastName',
            'accountHolderCity',
            'accountHolderHouseNumber',
            'accountHolderStreet',
            'consentChecked',
            'consentCollectMoneyFromAccountChecked',
            'invoiceSend',
            'iban',
        ]
        if (editBankDetails.accountHolderData.salutation === 'Firma') {
            missingFields.push('accountHolderCompany', 'accountHolderCompanyLegalForm')
        } else {
            const index = missingFields.indexOf('accountHolderCompany')
            missingFields.splice(index, 1)
            const index2 = missingFields.indexOf('accountHolderCompanyLegalForm')
            missingFields.splice(index2, 1)
        }
        const newErrors: string[] = cloneDeep(editInLineErrors)
        if (editBankDetails.differentAccountHolder) {
            if (
                salutationIsCompany(editBankDetails.accountHolderData.salutation) &&
                editBankDetails.accountHolderData.company !== ''
            ) {
                const index = missingFields.indexOf('accountHolderCompany')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (
                customizeJsData &&
                customizeJsData.contactDataViewConfiguration &&
                customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm &&
                salutationIsCompany(editBankDetails.accountHolderData.salutation) &&
                editBankDetails.accountHolderData.companyLegalForm !== ''
            ) {
                const index = missingFields.indexOf('accountHolderCompanyLegalForm')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editBankDetails.accountHolderData.salutation !== '') {
                const index = missingFields.indexOf('accountHolderSalutation')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editBankDetails.accountHolderData.name !== '') {
                const index = missingFields.indexOf('accountHolderName')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editBankDetails.accountHolderData.lastName !== '') {
                const index = missingFields.indexOf('accountHolderLastName')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editBankDetails.accountHolderData.city !== '') {
                const index = missingFields.indexOf('accountHolderCity')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editBankDetails.accountHolderData.houseNumber !== '') {
                const index = missingFields.indexOf('accountHolderHouseNumber')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editBankDetails.accountHolderData.street !== '') {
                const index = missingFields.indexOf('accountHolderStreet')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (editBankDetails.accountHolderData.zip !== '') {
                const index = missingFields.indexOf('accountHolderZip')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
                if (editBankDetails.accountHolderData.zip.length !== 5) {
                    newErrors.push('zipMustBe5Digits')
                } else {
                    const index = newErrors.indexOf('zipMustBe5Digits')
                    if (index !== -1) {
                        newErrors.splice(index, 1)
                    }
                }
            } else {
                const index = newErrors.indexOf('zipMustBe5Digits')
                if (index) {
                    newErrors.splice(index, 1)
                }
            }
        } else {
            missingFields = missingFields.filter(
                (e) =>
                    e !== 'accountHolderCompany' &&
                    e !== 'accountHolderCompanyLegalForm' &&
                    e !== 'accountHolderSalutation' &&
                    e !== 'accountHolderName' &&
                    e !== 'accountHolderLastName' &&
                    e !== 'accountHolderCity' &&
                    e !== 'accountHolderHouseNumber' &&
                    e !== 'accountHolderStreet' &&
                    e !== 'accountHolderZip',
            )
            if (editBankDetails.accountHolderData.zip.length !== 5) {
                newErrors.push('zipMustBe5Digits')
            } else {
                const index = newErrors.indexOf('zipMustBe5Digits')
                if (index !== -1) {
                    newErrors.splice(index, 1)
                }
            }
        }
        if (
            editBankDetails.isValidating &&
            editBankDetails.accountType === AccountType.IBAN &&
            editBankDetails.iban === ''
        ) {
            missingFields.push('iban')
        } else {
            const index = missingFields.indexOf('iban')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (editBankDetails.accountType === 'TRANSFER') {
            const errorIndex = newErrors.indexOf('insertValidIBAN')
            if (errorIndex) {
                newErrors.splice(errorIndex, 1)
            }
            const index = missingFields.indexOf('iban')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
            // reset the data if the user added some data
            if (editBankDetails.iban !== '') {
                setEditIban('')
            }
        }

        if (editBankDetails.consentChecked) {
            const index = missingFields.indexOf('consentChecked')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (showTwoConsentsIBAN && editBankDetails.accountType === AccountType.IBAN) {
            if (editBankDetails.consentCollectMoneyFromAccountChecked === true) {
                const index = missingFields.indexOf('consentCollectMoneyFromAccountChecked')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
        } else if (!showTwoConsentsIBAN) {
            const index = missingFields.indexOf('consentCollectMoneyFromAccountChecked')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }

        if (editSelectedInvoiceSendOption !== undefined) {
            const index = missingFields.indexOf('invoiceSend')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        return [missingFields, newErrors]
    }, [editBankDetails])
    const areThereAnyMissingPortabilityViewFields = useMemo(
        () => {
            const missing = []
            const newErrors: string[] = cloneDeep(editInLineErrors)
            if (editPhoneOptions.length > 0) {
                const firstAreaCode = editPhoneOptions[0].areaCode
                if (firstAreaCode === '' || editPhoneOptions[0].number === '') return true
                if (editPhoneOptions.length > 1) {
                    for (let i = 1; i < editPhoneOptions.length; i++) {
                        if (editPhoneOptions[i].areaCode === '' || editPhoneOptions[i].number === '') {
                            return true
                        }
                        if (editPhoneOptions[i].areaCode === editPhoneOptions[0].areaCode) {
                            const errorIndex = newErrors.indexOf('areaCode' + i)
                            if (errorIndex !== -1) {
                                newErrors.splice(errorIndex, 1)
                            }
                        }
                    }
                    setEditInLineErrors(newErrors)
                }

                if (customizeJsData) {
                    if (customizeJsData.portabilityConfiguration.previousProvider && editSelectedProvider === '') {
                        return true
                    }
                    if (editSelectedRadios[0] === PortabilityViewFields.ADDRESS + 'No') {
                        if (
                            customizeJsData.portabilityConfiguration.previousContractAddress &&
                            Object.entries(editAddress).filter(([k, v]) => (k === 'zip' ? v.length !== 5 : v === ''))
                                .length > 0
                        ) {
                            if (editAddress.zip.length > 0 && editAddress.zip.length !== 5) {
                                missing.push('contractAddress')
                                setMissingFields(missing)
                                addErrorToPortability('previousContractAddressZipMustBe5Digits')
                            }
                            return true
                        }
                    }
                    if (editSelectedRadios[1] === PortabilityViewFields.CONTRACT_HOLDER + 'No') {
                        if (
                            customizeJsData.portabilityConfiguration.previousContractHolder &&
                            editContractHolderOptions.filter(
                                (co) => Object.entries(co).filter(([, v]) => v === '').length > 0,
                            ).length > 0
                        ) {
                            return true
                        }
                    }
                    if (editSelectedRadios[2] === PortabilityViewFields.TERMINATED_CONTRACT + 'No') {
                        if (customizeJsData.portabilityConfiguration.previousContractTermination) {
                            // only check when the entered date ist complete
                            if (/\d\d.\d\d.\d\d\d\d/.test(editEndOfContract)) {
                                const date = parse(editEndOfContract, 'dd.MM.yyyy', new Date()).getTime()
                                if (!isNaN(date)) {
                                    return false
                                } else {
                                    missing.push('terminationDate')
                                    setMissingFields(missing)
                                    addErrorToPortability('invalidDateFormat')
                                    return true
                                }
                            }
                        }
                    }
                }
            } else {
                return true
            }
            return false
        },
        // eslint-disable-next-line
        [
            editAddress,
            editContractHolderOptions,
            customizeJsData,
            editEndOfContract,
            editPhoneOptions,
            editSelectedProvider,
            editSelectedRadios,
        ],
    )

    const validateContactData = (): boolean => {
        let returnBoolean = true
        const newErrors: string[] = [...editInLineErrors]
        if (!mailIsValid(editContactData.personalEmail)) {
            if (!editInLineErrors.some((err) => err === 'email')) {
                newErrors.push('email')
            }
            returnBoolean = false
        }
        switch (birthDateIsValid(editContactData.personalBirthDate)) {
            case 'invalid':
                newErrors.push('birthDate_invalid')
                returnBoolean = false
                break
            case 'tooYoung':
                newErrors.push('birthDate_min')
                returnBoolean = false
                break
            case 'tooOld':
                newErrors.push('birthDate_max')
                returnBoolean = false
                break
        }
        setEditInLineErrors(newErrors)
        return returnBoolean
    }
    const validateInstallationDetails = (): boolean => {
        const newErrors: string[] = [...editInLineErrors]
        let isValid = true
        if (customizeJsData) {
            if (customizeJsData.installationDetailsConfiguration.appartmentData) {
                const floorRequired =
                    customizeJsData.installationDetailsConfiguration.appartmentData.floorInformationRequired
                if (floorRequired && editInstallationDetails.floorInformation === '') {
                    // ETAGE
                    isValid = false
                    newErrors.push('floorInformationRequired')
                }
                const flatPositionRequired =
                    customizeJsData.installationDetailsConfiguration.appartmentData.flatPositionRequired
                if (flatPositionRequired && editInstallationDetails.flatPosition === '') {
                    // LAGE Wohnung
                    isValid = false
                    newErrors.push('flatPositionRequired')
                }
                const flatNumberRequired =
                    customizeJsData.installationDetailsConfiguration.appartmentData.flatNumberRequired
                if (flatNumberRequired && editInstallationDetails.flatNumber === '') {
                    // LAGE Wohnung
                    isValid = false
                    newErrors.push('flatNumberRequired')
                }
            }
        }
        setInLineErrors(newErrors)
        return isValid
    }
    const validateBankData = (): boolean => {
        let returnBoolean = true
        const newErrors: string[] = [...editInLineErrors]
        if (!IBAN.isValid(editBankDetails.iban)) {
            if (editBankDetails.accountType === 'IBAN') {
                if (!editInLineErrors.includes('iban')) {
                    newErrors.push('iban')
                }
                returnBoolean = false
            }
        } else {
            const errorIndex = newErrors.indexOf('iban')
            if (errorIndex !== -1) {
                newErrors.splice(errorIndex, 1)
            }
            returnBoolean = true
        }
        setEditInLineErrors(newErrors)
        setEditBankDetailsPartial({ isValidating: true })
        return returnBoolean
    }
    const validatePortabilityDetails = (): boolean => {
        let returnBoolean = true
        const newErrors: string[] = [...editInLineErrors]
        editPhoneOptions.forEach((op, i) => {
            if (i !== 0) {
                if (op.areaCode !== editPhoneOptions[0].areaCode) {
                    newErrors.push('areaCode' + i)
                    returnBoolean = false
                } else {
                    const errorIndex = newErrors.indexOf('areaCode' + i)
                    if (errorIndex !== -1) {
                        newErrors.splice(errorIndex, 1)
                    }
                }
            }
        })
        setEditInLineErrors(newErrors)
        return returnBoolean
    }

    let displayDeliveryAddress = true
    if (customizeJsData && customizeJsData.contactDataViewConfiguration.deliveryAddressConfiguration.enabled) {
        displayDeliveryAddress = false
        selectedProductCategories.map((currentProcutCategory) => {
            const selectedProductCategory = availableProductCategories.find((pc) => pc.id === currentProcutCategory.id)
            if (currentProcutCategory.selectedProduct) {
                const selectedProduct = selectedProductCategory?.products.find(
                    (p) => p.id === currentProcutCategory.selectedProduct?.id,
                )
                for (const currentProductType of currentProcutCategory.selectedProduct.productTypes) {
                    const selectedProductType = selectedProduct?.productTypes.find(
                        (pt) => pt.id === currentProductType.id,
                    )
                    for (const currentOptionCategory of currentProductType.optionCategories) {
                        const selectedOptionCategory = selectedProductType?.category.find(
                            (c) => c.id === currentOptionCategory.id,
                        )
                        if (selectedOptionCategory?.type == 'hardware') {
                            for (const options of currentOptionCategory.selectedOptions) {
                                if (
                                    !customizeJsData.contactDataViewConfiguration.deliveryAddressConfiguration.ownHardwareID.includes(
                                        options,
                                    )
                                ) {
                                    displayDeliveryAddress = true
                                }
                            }
                        }
                    }
                }
            }
        })
    }

    const orderOverviewPage = pagesList.find((page) => page.path === '/Bestellüberprüfung')
    const editPage = pagesList.find((page) => page.path === '/Bearbeiten')
    const portPage = pagesList.find((page) => page.path === '/RufnummernmitnahmeBearbeiten')
    const onBack = () => {
        history.replace(orderOverviewPage ? orderOverviewPage.path : pagesList[8].path)
        setInLineErrors([])
        setEditType(undefined)
    }
    const onBackPortability = () => {
        history.replace(editPage ? editPage.path : pagesList[8].path)
        setEditType(OnEditClickTypes.TELEPHONE)
    }
    const onSubmit = () => {
        switch (editType) {
            case OnEditClickTypes.TELEPHONE:
                const configurationMapStateCopyArray = [...cloneDeep(editConfigurationMapState).entries()]
                const goToPortability =
                    configurationMapStateCopyArray.find(
                        (entry) => entry[0] === 'takeYourNumber' && entry[1] === 'keepYourPhone',
                    ) === undefined
                        ? false
                        : true
                if (goToPortability) {
                    setEditType(OnEditClickTypes.PORTABILITY)
                    history.replace(portPage ? portPage.path : pagesList[10].path)
                    setConfigurationMapState(editConfigurationMapState)
                } else {
                    const copy = cloneDeep(editProviderOptions)
                    dispatch({ type: PortabilityStateAction.SET_TO_INIT_STATE, payload: null })
                    dispatch({ type: PortabilityStateAction.SET_PROVIDER_OPTIONS, payload: copy })
                    history.replace(orderOverviewPage ? orderOverviewPage.path : pagesList[8].path)
                    setConfigurationMapState(editConfigurationMapState)
                }
                break
            case OnEditClickTypes.PORTABILITY:
                if (validatePortabilityDetails()) {
                    setPhoneOptions(editPhoneOptions)
                    setEndOfContract(editEndOfContract)
                    setAddress(editAddress)
                    setContractHolderOptions(editContractHolderOptions)
                    setSelectedRadios(editSelectedRadios)
                    setSelectedProvider(editSelectedProvider)
                    history.replace(orderOverviewPage ? orderOverviewPage.path : pagesList[8].path)
                    setEditType(undefined)
                }
                break
            case OnEditClickTypes.INSTALLATION:
                if (validateInstallationDetails()) {
                    setInstallationDetailsState(editInstallationDetails)
                    history.replace(orderOverviewPage ? orderOverviewPage.path : pagesList[8].path)
                    setEditType(undefined)
                }
                break
            case OnEditClickTypes.ADDRESS:
                if (validateContactData()) {
                    setContactDataPartial(editContactData)
                    if (
                        validateInstallationDetails() &&
                        customizeJsData &&
                        customizeJsData.installationDetailsConfiguration.displayInContactPage
                    ) {
                        setInstallationDetailsState(editInstallationDetails)
                    }
                    history.replace(orderOverviewPage ? orderOverviewPage.path : pagesList[8].path)
                    setEditType(undefined)
                }
                break
            case OnEditClickTypes.DESIREDDATE:
                if (editEarliestDatePossible) {
                    setEarliestDatePossible(true)
                    setDesiredDate(null)
                } else {
                    setEarliestDatePossible(false)
                    setDesiredDate(editDesiredDate)
                }
                history.replace(orderOverviewPage ? orderOverviewPage.path : pagesList[8].path)
                setEditType(undefined)
                break
            case OnEditClickTypes.BANK:
                if (validateBankData()) {
                    setInvoiceSend(editSelectedInvoiceSendOption)
                    setDayOfTransfer(editBankDetails.dayOfTransfer)
                    setConsent(editBankDetails.consentChecked)
                    if (editBankDetails.transfer) {
                        setIban('')
                    }
                    if (!editBankDetails.differentAccountHolder) {
                        setBankDetailsPartial({
                            ...editBankDetails,
                            accountHolderData: {
                                city: '',
                                company: '',
                                companyLegalForm: '',
                                houseNumber: '',
                                lastName: '',
                                name: '',
                                salutation: '',
                                street: '',
                                title: '',
                                zip: '',
                                additionalInfoAddress: undefined,
                            },
                        })
                    } else setBankDetailsPartial(editBankDetails)
                    history.replace(orderOverviewPage ? orderOverviewPage.path : pagesList[8].path)
                    setEditType(undefined)
                }
                break
            default:
                break
        }
    }

    const setSalutationToCompany = useRef(false)
    if (B2B && !setSalutationToCompany.current) {
        setEditContactDataPartial({ personalSalutation: 'Firma' })
        setSalutationToCompany.current = true
    }
    const submitButtonTextAlt = useMemo(() => {
        if (editType === OnEditClickTypes.TELEPHONE) {
            const isKeepYourPhone = [...editConfigurationMapState].find((entry) => {
                if (typeof entry[1] === 'string') {
                    return (entry[1] as string) === 'keepYourPhone'
                } else {
                    return undefined
                }
            })
            if (isKeepYourPhone) {
                return true
            } else return false
        } else return false
    }, [editConfigurationMapState])

    const disabledBack = useMemo(() => {
        if (editType === OnEditClickTypes.TELEPHONE) {
            const isKeepYourPhone = [...editConfigurationMapState].find((entry) => {
                if (typeof entry[1] === 'string') {
                    return (entry[1] as string) === 'keepYourPhone'
                } else {
                    return undefined
                }
            })
            const wasKeepYourPhone = [...configurationMapState].find((entry) => {
                if (typeof entry[1] === 'string') {
                    return (entry[1] as string) === 'keepYourPhone'
                } else {
                    return undefined
                }
            })
            const portabilityIsEmpty = editPhoneOptions[0].areaCode === '' && editPhoneOptions[0].number === ''
            if (isKeepYourPhone && wasKeepYourPhone && portabilityIsEmpty) {
                return true
            } else return false
        } else return false
    }, [editPhoneOptions, editConfigurationMapState])

    const disabledSubmit = useMemo(() => {
        let disabled = true
        switch (editType) {
            case OnEditClickTypes.ADDRESS:
                if (JSON.stringify(editContactData) === JSON.stringify(contactData)) {
                    disabled = true
                } else {
                    if (areThereAnyMissingContactDataFields.length === 0) {
                        disabled = false
                    } else {
                        disabled = true
                    }
                }
                if (
                    disabled &&
                    customizeJsData &&
                    customizeJsData.installationDetailsConfiguration.displayInContactPage
                ) {
                    if (JSON.stringify(editInstallationDetails) === JSON.stringify(installationDetails)) {
                        disabled = true
                    } else {
                        disabled = false
                    }
                }
                break
            case OnEditClickTypes.BANK:
                if (JSON.stringify(editBankDetails) === JSON.stringify(bankDetails)) {
                    if ([...generalState.configuration].some((config) => config[1] === editSelectedInvoiceSendOption)) {
                        disabled = true
                    } else {
                        disabled = false
                    }
                } else {
                    if (areThereAnyMissingBankDetailsFields.length === 0) {
                        disabled = false
                    }
                }
                break
            case OnEditClickTypes.DESIREDDATE:
                if (JSON.stringify(editDesiredDate) === JSON.stringify(desiredDate)) {
                    disabled = true
                    if (editEarliestDatePossible !== earliestDatePossible) {
                        disabled = false
                    }
                    if (!editEarliestDatePossible && !isDate(editDesiredDate)) {
                        disabled = true
                    }
                } else {
                    disabled = false
                }
                break
            case OnEditClickTypes.INSTALLATION:
                if (JSON.stringify(editInstallationDetails) === JSON.stringify(installationDetails)) {
                    disabled = true
                } else {
                    disabled = false
                }
                break
            case OnEditClickTypes.TELEPHONE:
                if (JSON.stringify([...editConfigurationMapState]) === JSON.stringify([...configurationMapState])) {
                    const found = [...editConfigurationMapState].find((entry) => {
                        if (typeof entry[1] === 'string') {
                            return (entry[1] as string) === 'keepYourPhone'
                        } else {
                            return undefined
                        }
                    })
                    if (found) {
                        disabled = false
                    } else disabled = true
                } else {
                    disabled = false
                }
                break
            case OnEditClickTypes.PORTABILITY:
                if (
                    (JSON.stringify(editAddress) !== JSON.stringify(address) ||
                        JSON.stringify(editContractHolderOptions) !== JSON.stringify(contractHolderOptions) ||
                        JSON.stringify(editEndOfContract) !== JSON.stringify(endOfContract) ||
                        JSON.stringify(editPhoneOptions) !== JSON.stringify(phoneOptions) ||
                        JSON.stringify(editProviderOptions) !== JSON.stringify(providerOptions) ||
                        JSON.stringify(editSelectedProvider) !== JSON.stringify(selectedProvider) ||
                        JSON.stringify(editSelectedRadios) !== JSON.stringify(selectedRadios)) &&
                    !areThereAnyMissingPortabilityViewFields
                ) {
                    disabled = false
                }
                break
            default:
                break
        }
        return disabled
    }, [
        editEarliestDatePossible,
        editDesiredDate,
        editBankDetails,
        editContactData,
        editInstallationDetails,
        editConfigurationMapState,
        editAddress,
        editContractHolderOptions,
        editEndOfContract,
        editPhoneOptions,
        editProviderOptions,
        editSelectedProvider,
        editSelectedRadios,
        editSelectedInvoiceSendOption,
        areThereAnyMissingPortabilityViewFields,
    ])

    const setEditType = useCallback(
        (payload: OnEditClickTypes | undefined): void => {
            dispatch({ type: GeneralStateAction.SET_EDIT_TYPE, payload })
        },
        [dispatch],
    )

    const setInLineErrors = useCallback(
        (payload: string[]) => {
            dispatch({ type: GeneralStateAction.SET_INLINE_ERRORS, payload })
        },
        [dispatch],
    )

    // Desired Date
    const setDesiredDate = useCallback(
        (payload: Date | null) => {
            dispatch({ type: GeneralStateAction.SET_DESIRED_DATE, payload })
        },
        [dispatch],
    )
    const setEarliestDatePossible = useCallback(
        (payload: boolean) => {
            dispatch({ type: GeneralStateAction.SET_EARLIEST_DATE_POSSIBLE, payload })
        },
        [dispatch],
    )
    const minDate = (): Date => {
        const dummyToday: Date = new Date()
        if (customizeJsData) {
            const today = DateFns.addBusinessDays(
                new Date(),
                customizeJsData.installationConfiguration.minInstallationDate,
            )
            if (startOfDelivery) {
                const deliveryDate = new Date(startOfDelivery)
                if (today.getTime() < deliveryDate.getTime()) {
                    return deliveryDate
                }
            }
            return today
        } else {
            return dummyToday
        }
    }
    const maxDate = (): Date | number => {
        if (customizeJsData) {
            const today = new Date()
            if (customizeJsData.installationConfiguration.maxInstallationDate === 0) {
                return 0
            }
            if (startOfDelivery) {
                const deliveryDate = new Date(startOfDelivery)
                if (today.getTime() < deliveryDate.getTime()) {
                    return DateFns.addBusinessDays(
                        deliveryDate,
                        customizeJsData.installationConfiguration.maxInstallationDate,
                    )
                }
            }
            return DateFns.addBusinessDays(today, customizeJsData.installationConfiguration.maxInstallationDate)
        } else {
            return 0
        }
    }
    const formatDateString = (date: Date): string => DateFns.format(new Date(date), 'dd.MM.yyyy').toString()
    const shouldDisableDate = (day: Date | null): boolean => {
        if (day && DateFns.isWeekend(day)) {
            return true
        }
        return false
    }

    // Contact Data
    const setEditContactAddress = (
        zip: string,
        city: string,
        street: string,
        houseNumber: string,
        additionalInfo: string,
        delivery: boolean,
    ) => {
        if (delivery) {
            setEditContactData({
                ...editContactData,
                deliveryZip: zip,
                deliveryCity: city,
                deliveryStreet: street,
                deliveryHouseNumber: houseNumber,
                deliveryAdditionalAddrInfo: additionalInfo,
            })
        } else {
            setEditContactData({
                ...editContactData,
                billingZip: zip,
                billingCity: city,
                billingStreet: street,
                billingHouseNumber: houseNumber,
                billingAdditionalAddrInfo: additionalInfo,
            })
        }
    }

    // Bank Details
    const setEditBankAddress = (
        zip: string,
        city: string,
        street: string,
        houseNumber: string,
        additionalInfo: string,
    ) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: {
                ...editBankDetails.accountHolderData,
                zip: zip,
                city: city,
                street: street,
                houseNumber: houseNumber,
                additionalInfoAddress: additionalInfo,
            },
        })
    }

    const setEditConsent = (value: boolean) => {
        setEditBankDetails({
            ...editBankDetails,
            consentChecked: value,
        })
    }

    const setEditConsent2 = (value: boolean) => {
        setEditBankDetails({
            ...editBankDetails,
            consentCollectMoneyFromAccountChecked: value,
        })
    }

    const setEditAccountHolderAdditionalAddrInfo = (value: string | undefined) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, additionalInfoAddress: value },
        })
    }
    const setEditAccountHolderCountryAddr = (value: string | undefined) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, countryAddress: value },
        })
    }
    const setEditAccountHolderSalutation = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, salutation: value },
        })
    }

    const setEditAccountHolderTitle = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, title: value },
        })
    }

    const setEditAccountHolderName = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, name: value },
        })
    }

    const setEditAccountHolderCompany = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, company: value },
        })
    }
    const setEditAccountHolderCompanyLegalForm = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, companyLegalForm: value },
        })
    }

    const setEditAccountHolderLastName = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, lastName: value },
        })
    }
    const setEditAccountHolderStreet = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, street: value },
        })
    }
    const setEditAccountHolderHouseNumber = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, houseNumber: value },
        })
    }
    const setEditAccountHolderZip = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, zip: value },
        })
    }
    const setEditAccountHolderCity = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            accountHolderData: { ...editBankDetails.accountHolderData, city: value },
        })
    }
    const setEditIban = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            iban: value,
        })
    }

    const setEditAccountType = (value: AccountType) => {
        setEditBankDetails({
            ...editBankDetails,
            accountType: value,
            iban: '',
        })
    }
    const setEditDifferentAccountHolder = (value: boolean) => {
        setEditBankDetails({
            ...editBankDetails,
            differentAccountHolder: value,
        })
    }

    const setEditDayOfTransfer = (value: string) => {
        setEditBankDetails({
            ...editBankDetails,
            dayOfTransfer: value,
        })
    }

    const setDayOfTransfer = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_DAY_OF_TRANSFER, payload })
        },
        [dispatch],
    )
    const setBankDetailsPartial = useCallback(
        (payload: Partial<BankDetailsState>) => {
            dispatch({ type: BankDetailsAction.SET_BANK_DETAILS_PARTIAL, payload })
        },
        [dispatch],
    )
    const setIban = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_IBAN, payload })
        },
        [dispatch],
    )
    const setConsent = useCallback(
        (payload: boolean) => {
            dispatch({ type: BankDetailsAction.SET_CONSENT, payload })
        },
        [dispatch],
    )
    const setInvoiceSend = useCallback(
        (value: string) => {
            dispatch({ type: GeneralStateAction.SET_CONFIG_VALUE, payload: { identifier: 'invoiceSend', value } })
        },
        [dispatch],
    )

    const setContactDataPartial = useCallback(
        (payload: Partial<ContactDataState>) => {
            dispatch({ type: ContactDataAction.SET_CONTACT_STATE_PARTIAL, payload })
        },
        [dispatch],
    )

    const editLabels = useMemo(() => {
        return getBankAccountApproval(editBankDetails.accountType, editBankDetails.differentAccountHolder)
    }, [editBankDetails.accountType, editBankDetails.differentAccountHolder])

    const invoiceOptions: ConfigurationItemOption[] = [
        {
            columnValue: 6,
            label: B2B ? 'viaEmail_B2B' : 'viaEmail',
            mobileColumnValue: 12,
            value: 'viaEmail',
        },
        {
            columnValue: 6,
            label: B2B ? 'viaMail_B2B' : 'viaMail',
            mobileColumnValue: 12,
            value: 'viaMail',
        },
    ]

    const dayOfTransferOptions: ConfigurationItemOption[] = [
        {
            columnValue: 12,
            label: t('bankDetailsStrings.transfer15'),
            mobileColumnValue: 12,
            value: '15',
        },
        {
            columnValue: 12,
            label: t('bankDetailsStrings.transfer29'),
            mobileColumnValue: 12,
            value: '29',
        },
    ]

    // Installation Details

    const setInstallationDetailsState = useCallback(
        (payload: InstallationDetailsState) => {
            dispatch({ type: GeneralStateAction.SET_INSTALLATION_DETAILS, payload })
        },
        [dispatch],
    )

    const onChange = (field: InstallationDetailsFields, value: string | number): void => {
        const newInstallationDetailsState = cloneDeep(editInstallationDetails)
        switch (field) {
            case InstallationDetailsFields.FLAT_NUMBER:
                newInstallationDetailsState.flatNumber = String(value)
                break
            case InstallationDetailsFields.FLAT_POSITION:
                newInstallationDetailsState.flatPosition = String(value)
                break
            case InstallationDetailsFields.FLOOR_INFO:
                newInstallationDetailsState.floorInformation = String(value)
                break
            case InstallationDetailsFields.JUNCTION_BOX:
                newInstallationDetailsState.junctionBox = String(value)
                break
            case InstallationDetailsFields.HOUSE_CONNECTION:
                newInstallationDetailsState.houseConnection = String(value)
                break
            case InstallationDetailsFields.OPTIC_FIBER:
                newInstallationDetailsState.opticFiber = String(value)
                break
            case InstallationDetailsFields.OPTIC_FIBER_INPUT:
                newInstallationDetailsState.opticFiberInput = String(value)
                break
            case InstallationDetailsFields.HOUSE_CONNECTION_INPUT:
                newInstallationDetailsState.houseConnectionInput = String(value)
                break
            case InstallationDetailsFields.SERVICE:
                if (newInstallationDetailsState.installationService === value) {
                    newInstallationDetailsState.installationService = 0
                } else {
                    newInstallationDetailsState.installationService = Number(value)
                }
                break
        }
        setEditInstallationDetails(newInstallationDetailsState)
    }

    const convertJunctionBoxOptionToOptionItemContent = (
        junctionBox: ConfigurationJunctionBox,
    ): OptionItemContent[] => {
        return junctionBox.options.map((option: JunctionBoxOption) => {
            return {
                id: option.title,
                selectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
                info: t('installationDetailsStrings.junctionBox.' + option.information) ?? '',
                title: t('installationDetailsStrings.junctionBox.' + option.title),
                unselectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
            }
        })
    }

    const convertHouseConnectionOptionToOptionItemContent = (
        houseConnection: ConfigurationHouseConnection,
    ): OptionItemContent[] => {
        return houseConnection.options.map((option: HouseConnectionOption) => {
            return {
                id: option.title,
                selectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
                info: t('installationDetailsStrings.houseConnection.' + option.information) ?? '',
                title: t('installationDetailsStrings.houseConnection.' + option.title),
                unselectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
            }
        })
    }

    const convertOpticFiberOptionToOptionItemContent = (opticFiber: ConfigurationOpticFiber): OptionItemContent[] => {
        return opticFiber.options.map((option: OpticFiberOption) => {
            return {
                id: option.title,
                selectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
                info: t('installationDetailsStrings.opticFiber.' + option.information) ?? '',
                title: t('installationDetailsStrings.opticFiber.' + option.title),
                unselectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
            }
        })
    }

    const convertYoutubeLinkToID = (link: string): string => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
        const match = link.match(regExp)
        if (match) {
            return match[7]
        }
        return ''
    }

    let submitButtonText: string | undefined = undefined
    if (customizeJsData && customizeJsData.voucherConfiguration.enableManualPage) {
        submitButtonText = t(currentView + 'ButtonText2')
    }

    // Telephone Configuration
    const setConfigurationMapState = useCallback(
        (payload: Map<string, string | string[]>) => {
            dispatch({ type: GeneralStateAction.SET_TELEPHONE_DETAILS, payload })
        },
        [dispatch],
    )

    const setConfigurationMapRadioState = useCallback(
        (payload: ConfigPayload) => {
            dispatch({ type: GeneralStateAction.SET_CONFIG_VALUE, payload })
        },
        [dispatch],
    )

    const setConfigurationMapCheckState = useCallback(
        (payload: ConfigPayload) => {
            dispatch({ type: GeneralStateAction.TOGGLE_CONFIG_VALUE, payload })
        },
        [dispatch],
    )

    const convertOptionToOptionItem = (
        option: OptionConfiguration,
        displayType: ConfigurationDisplayType,
        optionsLength: number,
    ): ConfigurationItemOption => {
        // @ts-expect-error: should return  the same value
        const newColumnValue: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined =
            optionsLength > 0 && Math.floor(12 / optionsLength)
        switch (displayType) {
            case ConfigurationDisplayType.FREE:
                return {
                    columnValue: 'auto',
                    label: option.labelKey,
                    mobileColumnValue: 12,
                    value: option.value,
                }
            case ConfigurationDisplayType.MULTI_LINE:
                return {
                    columnValue: 12,
                    label: option.labelKey,
                    mobileColumnValue: 12,
                    value: option.value,
                }
            case ConfigurationDisplayType.ONE_LINE:
                return {
                    columnValue: newColumnValue,
                    label: option.labelKey,
                    mobileColumnValue: 12,
                    value: option.value,
                }
        }
    }

    // Portability View
    const [missingFields, setMissingFields] = useState<string[]>([])

    const addErrorToPortability = useCallback(
        (error: string) => {
            dispatch({
                type: GeneralStateAction.ADD_ERROR_TO_ERROR_CATEGORY,
                payload: {
                    category: ViewType.PORTABILITY_DETAILS,
                    error,
                },
            })
        },
        [dispatch],
    )

    const removeErrorFromPortability = useCallback(
        (error: string) =>
            dispatch({
                type: GeneralStateAction.REMOVE_ERROR_FROM_ERROR_CATEGORY,
                payload: { category: ViewType.PORTABILITY_DETAILS, error },
            }),
        [dispatch],
    )

    const setPhoneOptions = useCallback(
        (payload: PortabilityTelephone[]) => {
            setMissingFields((missingFields) =>
                missingFields.filter((field) => field !== 'areaCode' && field !== 'phoneOption'),
            )
            removeErrorFromPortability('areaCodesMustBeTheSame')
            removeErrorFromPortability('phoneOptionsMustBeFilled')
            dispatch({ type: PortabilityStateAction.SET_PHONE_OPTIONS, payload })
        },
        // eslint-disable-next-line
        [dispatch],
    )

    const setEndOfContract = useCallback(
        (payload: string) => {
            dispatch({ type: PortabilityStateAction.SET_END_OF_CONTRACT, payload })
        },
        [dispatch],
    )

    const setAddress = useCallback(
        (payload: PortabilityAddress) => {
            dispatch({ type: PortabilityStateAction.SET_ADDRESS, payload })
        },
        [dispatch],
    )

    const setContractHolderOptions = useCallback(
        (payload: PortabilityContractHolder[]) => {
            dispatch({ type: PortabilityStateAction.SET_CONTRACT_HOLDER_OPTIONS, payload })
        },
        [dispatch],
    )

    const setSelectedRadios = useCallback(
        (payload: string[]) => {
            dispatch({ type: PortabilityStateAction.SET_SELECTED_RADIO, payload })
        },
        [dispatch],
    )

    const setSelectedProvider = useCallback(
        (payload: string) => {
            dispatch({ type: PortabilityStateAction.SET_SELECTED_PROVIDER, payload })
        },
        [dispatch],
    )

    const phoneNumbersError = (field: string, index: number): boolean => {
        return editInLineErrors.includes(field + index)
    }
    const onChangePortability = (field: PortabilityViewFields, label: string, index: number, value: string): void => {
        switch (field) {
            case PortabilityViewFields.TELEPHONE:
                const newPhoneOptions = cloneDeep(editPhoneOptions)
                switch (label) {
                    case 'FIRST_LABEL':
                        newPhoneOptions[index].areaCode = value
                        setEditPhoneOptions(newPhoneOptions)
                        break
                    case 'SECOND_LABEL':
                        newPhoneOptions[index].number = value
                        setEditPhoneOptions(newPhoneOptions)
                        break
                }
                break
            case PortabilityViewFields.CONTRACT_HOLDER:
                const newContractHolderOptions = cloneDeep(editContractHolderOptions)
                const newSelectedRadios1 = cloneDeep(editSelectedRadios)
                switch (label) {
                    case 'FIRST_LABEL':
                        newContractHolderOptions[index].firstName = value
                        setEditContractHolderOptions(newContractHolderOptions)
                        break
                    case 'SECOND_LABEL':
                        newContractHolderOptions[index].lastName = value
                        setEditContractHolderOptions(newContractHolderOptions)
                        break
                    case 'RADIO':
                        newSelectedRadios1[1] = value
                        setEditSelectedRadios(newSelectedRadios1)
                        break
                }
                break
            case PortabilityViewFields.ADDRESS:
                const newAddress = cloneDeep(editAddress)
                const newSelectedRadios2 = cloneDeep(editSelectedRadios)
                switch (label) {
                    case 'STREET':
                        newAddress.street = value
                        setEditAddress(newAddress)
                        break
                    case 'HOUSE_NUMBER':
                        newAddress.houseNumber = value
                        setEditAddress(newAddress)
                        break
                    case 'ZIP':
                        newAddress.zip = value
                        setMissingFields((missingFields) =>
                            missingFields.filter((field) => field !== 'contractAddress'),
                        )
                        removeErrorFromPortability('previousContractAddressZipMustBe5Digits')
                        setEditAddress(newAddress)
                        break
                    case 'CITY':
                        newAddress.city = value
                        setEditAddress(newAddress)
                        break
                    case 'RADIO':
                        newSelectedRadios2[0] = value
                        setMissingFields((missingFields) =>
                            missingFields.filter((field) => field !== 'contractAddress'),
                        )
                        removeErrorFromPortability('previousContractAddressZipMustBe5Digits')
                        setEditSelectedRadios(newSelectedRadios2)
                        break
                }
                break
            case PortabilityViewFields.TERMINATED_CONTRACT:
                let newEndOfContract = editEndOfContract
                const newSelectedRadios3 = cloneDeep(editSelectedRadios)
                switch (label) {
                    case 'EndOfContract':
                        newEndOfContract = value
                        setMissingFields((missingFields) =>
                            missingFields.filter((field) => field !== 'terminationDate'),
                        )
                        removeErrorFromPortability('invalidDateFormat')
                        removeErrorFromPortability('terminationDateCantBeInTheFuture')
                        setEditEndOfContract(newEndOfContract)
                        break
                    case 'RADIO':
                        newSelectedRadios3[2] = value
                        setMissingFields((missingFields) =>
                            missingFields.filter((field) => field !== 'terminationDate'),
                        )
                        removeErrorFromPortability('invalidDateFormat')
                        removeErrorFromPortability('terminationDateCantBeInTheFuture')
                        setEditSelectedRadios(newSelectedRadios3)
                        break
                }
                break
            case PortabilityViewFields.PROVIDER:
                setEditSelectedProvider(value)
                break
        }
    }
    const editAddOption = (field: PortabilityViewFields): void => {
        if (field === PortabilityViewFields.TELEPHONE) {
            setEditPhoneOptions([...editPhoneOptions, { areaCode: '', number: '' }])
        }
        if (field === PortabilityViewFields.CONTRACT_HOLDER) {
            setEditContractHolderOptions([...editContractHolderOptions, { firstName: '', lastName: '' }])
        }
    }
    const editRemoveOption = (field: PortabilityViewFields): void => {
        if (field === PortabilityViewFields.TELEPHONE) {
            const newPhoneOptions = cloneDeep(editPhoneOptions)
            newPhoneOptions.splice(newPhoneOptions.length - 1, 1)
            setEditPhoneOptions(newPhoneOptions)
        }
        if (field === PortabilityViewFields.CONTRACT_HOLDER) {
            const newContractHolderOptions = cloneDeep(editContractHolderOptions)
            newContractHolderOptions.splice(newContractHolderOptions.length - 1, 1)
            setEditContractHolderOptions(newContractHolderOptions)
        }
    }

    const isOptionEmpty = (option: PortabilityTelephone | PortabilityContractHolder): boolean => {
        return Object.entries(option).filter(([, v]) => v === '').length > 0
    }

    const handleEditAddressSelected = (data: PostCodeDetail) => {
        setEditAddress({
            street: data.address.street,
            zip: data.address.postcode,
            houseNumber: data.address.buildingNumber.toString(),
            city: data.address.locality,
        })
    }

    const handleEditSelectProvider = (_: React.ChangeEvent<any>, value: string) => {
        onChangePortability(PortabilityViewFields.PROVIDER, '', -1, value)
    }

    const handleEditInputProvider = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangePortability(PortabilityViewFields.PROVIDER, '', -1, event.target.value)
    }

    // check requiredFields on userAction
    useEffect(() => {
        if (customizeJsData) {
            const requiredFields: string[] = []
            customizeJsData.telephoneOptions.telephoneConfiguration.forEach((v) => {
                if (v.required === true) {
                    requiredFields.push(v.identifier)
                }
                if (v.type === ConfigurationType.CONDITIONAL && v.render) {
                    if (v.condition) {
                        const conditionIsGiven = editConfigurationMapState.get(v.condition.identifier)
                        // check children if condition isIn Map
                        if (conditionIsGiven !== undefined) {
                            v.render.forEach((children) => {
                                if (children.required === true) {
                                    requiredFields.push(children.identifier)
                                }
                                if (!editConfigurationMapState.has(children.identifier)) {
                                    children.options?.forEach((option: OptionConfiguration) => {
                                        if (option.preselected) {
                                            const valueInMap = editConfigurationMapState.get(children.identifier)
                                            if (
                                                (valueInMap && !valueInMap.includes(option.value)) ||
                                                valueInMap === undefined
                                            ) {
                                                editConfigurationMapState.set(children.identifier, option.value)
                                            }
                                        }
                                    })
                                }
                            })
                        } else {
                            // remove children items out of configurationMapState
                            v.render.forEach((remove) => {
                                editConfigurationMapState.delete(remove.identifier)
                            })
                        }
                    }
                }
            })
        }
    }, [editConfigurationMapState])

    useEffect(() => {
        if (!editBankDetails.differentAccountHolder) {
            setBankDetailsPartial({
                ...editBankDetails,
                accountHolderData: {
                    city: '',
                    company: '',
                    companyLegalForm: '',
                    houseNumber: '',
                    lastName: '',
                    name: '',
                    salutation: '',
                    street: '',
                    title: '',
                    zip: '',
                    additionalInfoAddress: undefined,
                },
            })
        }
    }, [])

    useEffect(() => {
        if (!editBankDetails.isValidating) {
            if (editBankDetails.accountType !== bankDetails.accountType) {
                setEditBankDetails({
                    ...editBankDetails,
                    consentChecked: false,
                })
            } else {
                if (editBankDetails.accountType === 'IBAN') {
                    setEditBankDetails({
                        ...editBankDetails,
                        consentChecked: true,
                        iban: bankDetails.iban,
                    })
                } else {
                    setEditBankDetails({
                        ...editBankDetails,
                        consentChecked: true,
                    })
                }
            }
        }
    }, [editBankDetails.accountType])

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [history.location.pathname])

    return {
        loadState,
        onBack,
        onSubmit,
        editType,
        generalState,
        disabledSubmit,
        disabledBack,

        editInLineErrors,
        errors,

        editDesiredDate,
        editEarliestDatePossible,
        setEditDesiredDate,
        setEditEarliestDatePossible,
        formatDateString,
        maxDate,
        minDate,
        shouldDisableDate,

        setEditAccountType,
        editBankDetails,
        editDayOfTransfer: editBankDetails.dayOfTransfer,
        setEditDayOfTransfer,
        customizeJsData,
        showTwoConsentsIBAN,
        consentLabel: editLabels.consentLabel,
        consentLabel2: editLabels.consentLabel2,
        invoiceOptions,
        dayOfTransferOptions,
        setEditAccountHolderCity,
        setEditAccountHolderHouseNumber,
        setEditAccountHolderLastName,
        setEditAccountHolderName,
        setEditAccountHolderSalutation,
        setEditAccountHolderStreet,
        setEditAccountHolderTitle,
        setEditAccountHolderZip,
        setEditAccountHolderCompany,
        setEditAccountHolderCompanyLegalForm,
        setEditIban,
        setEditDifferentAccountHolder,
        setEditAccountHolderAdditionalAddrInfo,
        setEditAccountHolderCountryAddr,
        setEditInvoiceSend,
        editSelectedInvoiceSendOption,
        setEditConsent,
        setEditConsent2,
        setEditBankDetailsPartial,
        setEditBankDetails,

        availability,
        b2b: B2B,
        editContactData,
        setEditContactDataPartial,
        setEditContactData,

        editInstallationDetails,
        setEditInstallationDetails,
        convertJunctionBoxOptionToOptionItemContent,
        convertHouseConnectionOptionToOptionItemContent,
        convertOpticFiberOptionToOptionItemContent,
        convertYoutubeLinkToID,
        onChange,
        submitButtonText,

        editConfigurationMapState,
        convertOptionToOptionItem,
        setEditConfigurationMapState,
        setConfigurationMapCheckState,
        setConfigurationMapRadioState,
        submitButtonTextAlt,

        editAddOption,
        editAddress,
        editContractHolderOptions,
        editEndOfContract,
        handleEditAddressSelected,
        handleEditInputProvider,
        handleEditSelectProvider,
        isOptionEmpty,
        missingFields,
        onChangePortability,
        phoneNumbersError,
        editPhoneOptions,
        editProviderOptions,
        editRemoveOption,
        editSelectedProvider,
        editSelectedRadios,
        onBackPortability,
        setEditContactAddress,
        setEditBankAddress,
        displayDeliveryAddress,
    }
}
